/**
 * Acc지원 정보 - 창업 프로그램 참여경험 DTO
 * @see {@link https://wiki.dpteam.net/pages/viewpage.action?pageId=22120188 Front.Acc액셀러레이팅메인 정보조회}
 */
export default class AccStartPgJoinExpDto {
  constructor(obj) {
    this.start_pg_nostart_pg_no = obj.start_pg_nostart_pg_no;
    this.acc_no = obj.acc_no;
    this.join_start_date = obj.join_start_date;
    this.join_end_date = obj.join_end_date;
    this.start_pg_name = obj.start_pg_name;
    this.aword_yn = obj.aword_yn === '1' ? '1' : '0';
    this.benefit = obj.benefit;
    this.display_order = obj.display_order || -1;
  }
}

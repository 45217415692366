/**
 * Acc지원 정보 - 멤버 DTO
 * @see {@link https://wiki.dpteam.net/pages/viewpage.action?pageId=22120188 Front.Acc액셀러레이팅메인 정보조회}
 */
 export default class AccMemberInfoDto {
  constructor(obj) {
    this.acc_member_no = obj.acc_member_no;
    this.acc_no = obj.acc_no;
    this.worker_kind = obj.worker_kind;
    this.name = obj.name;
    this.position = obj.position;
    this.career = obj.career;
    this.display_order = obj.display_order || -1;
    this.cellphone = obj.cellphone;
    this.email = obj.email;
    this.member_gender = obj.member_gender;
    // - 추가항목(신규)
    // : 소속
    // : MBTI(입력필드)
    // : 보유기술 스택(solidity, go, java, react, rust, chaincode 복수선택/체크박스)
    // : 프로그래밍 활용 능력(1~5/라디오 버튼) > (문구추가) 개발자만 입력 및 보통 1점 ~ 탁월함 5점
    // : 백신 접종 여부(예/아니오 중 택일 /라디오 버튼) > (문구추가) 미 접종자의 경우 선정 시 참가 전 신속항원 결과서 제출 필수
    this.department = obj.department;
    this.mbti = obj.mbti;
    this.skill = obj.skill;
    console.log(this.skill, obj.skill);
    if (this.skill) {
      this.skills = this.skill.split(',');
    } else {
      this.skills = [];
    }
    this.programing_level = obj.programing_level;
    this.vaccine = obj.vaccine;
  }
}

<template>
  <div class="form-container">
    <ValidationObserver ref="form">
      <!-- 멤버정보 입력 폼 -->
      <form>
        <fieldset>
          <legend>{{$t('content.accelerating.academy.Common.txt03')}}</legend>
          <div class="align-right">
            <!-- *는 필수입력 항목입니다. -->
            <em class="required-mark">*</em>{{ $t('content.networking.CompanyInfo.txt53') }}
          </div>
          <ul>
            <!-- 멤버 -->
            <li
              v-if="accApplyPgmMngDetail.nameYn || accApplyPgmMngDetail.workerKindYn ||
                accApplyPgmMngDetail.positionYn || accApplyPgmMngDetail.careerYn ||
                accApplyPgmMngDetail.memberCellphoneYn || accApplyPgmMngDetail.memberEmailYn"
              class="form-row">

              <!-- (AC114) Corporate ACC-Hyundai인 경우 : '멤버'대신에 '담당자'로 표시 -->
              <template v-if="accApplyPgmMngDetail.accKind === 'AC114'">
                <BaseLabelElement
                  id="name"
                  :label="$t('commonCode.PL1004')"
                  required
                />
              </template>
              <!-- 그 외의 경우 : '멤버'로 표시 -->
              <template v-else>
                <div>
                  <BaseLabelElement
                    id="name"
                    :label="$t('content.accelerating.academy.MemberInfo.txt01')"
                    style="display: inline-block;"
                    required
                  />
                  <span style="color:#3190d6;">맴버 4인 구성은 필수입니다.</span>
                </div>
              </template>

              <!-- 이름 -->
              <ValidationProvider
                v-if="accApplyPgmMngDetail.nameYn"
                vid="01_member_001_name"
                :name="$t('content.accelerating.academy.MemberInfo.txt02')"
                :rules="{ required: true }"
                v-slot="{ failed }"
                slim
              >
                <BaseInputElement
                  ref="01_member_001_name"
                  v-model="accMemberInfo[0].name"
                  width="half"
                  id="name"
                  :placeholder="$t('content.accelerating.academy.MemberInfo.txt02')"
                  required
                  :invalid="failed"
                />
              </ValidationProvider>
              <!-- 성별 -->
              <div
                v-if="accApplyPgmMngDetail.member_gender && accApplyPgmMngDetail.accName.indexOf('해커톤') !== -1"
                class="input-container input-md">
                <ValidationProvider
                  vid="01_member_002_gender"
                  :name="$t('content.accelerating.academy.MemberInfo.txt23')"
                  :rules="{ required: true }"
                  slim
                >
                  <!-- 남 -->
                  <BaseRadioButton
                    ref="01_member_002_gender"
                    name="gender"
                    v-model="accMemberInfo[0].member_gender"
                    value="0"
                    required
                  >{{$t('content.accelerating.academy.MemberInfo.txt21')}}</BaseRadioButton>
                  <!-- 여 -->
                  <BaseRadioButton
                    name="gender"
                    v-model="accMemberInfo[0].member_gender"
                    value="1"
                    required
                  >{{$t('content.accelerating.academy.MemberInfo.txt22')}}</BaseRadioButton>
                </ValidationProvider>
              </div>
              <!-- 생년월일 -->
              <ValidationProvider
                v-if="accApplyPgmMngDetail.careerYn"
                vid="01_member_004_career"
                :name="$t('content.accelerating.academy.MemberInfo.txt06')"
                :rules="{ required: true }"
                v-slot="{ failed }"
                slim
              >
                <BaseInputElement
                  ref="01_member_004_career"
                  type="date"
                  v-model="accMemberInfo[0].career"
                  width="half"
                  id="career"
                  required
                  :invalid="failed"
                  :maxlength="8"
                  placeholder="생년월일"
                />
              </ValidationProvider>
              <!-- 소속 -->
              <ValidationProvider
                v-if="accApplyPgmMngDetail.workerKindYn"
                vid="01_member_002_worker_kind"
                :name="$t('content.accelerating.academy.MemberInfo.txt19')"
                :rules="{ required: true }"
                v-slot="{ failed }"
                slim
              >
                <BaseInputElement
                  ref="01_member_002_worker_kind"
                  v-model="accMemberInfo[0].department"
                  width="half"
                  id="department"
                  :placeholder="$t('content.accelerating.academy.MemberInfo.txt19')"
                  required
                  :invalid="failed"
                />
              </ValidationProvider>
              <!-- 휴대폰번호 -->
              <ValidationProvider
                v-if="accApplyPgmMngDetail.memberCellphoneYn"
                vid="01_member_005_cellphone"
                :name="$t('content.accelerating.academy.MemberInfo.txt17')"
                :rules="{ required: true, phone: true }"
                v-slot="{ failed }"
                slim
              >
                <BaseInputElement
                  ref="01_member_005_cellphone"
                  type="tel"
                  v-model="accMemberInfo[0].cellphone"
                  width="half"
                  id="cellphone"
                  :placeholder="$t('content.accelerating.academy.MemberInfo.txt20')"
                  required
                  :invalid="failed"
                  maxlength="13"
                />
              </ValidationProvider>

              <!-- 이메일 -->
              <ValidationProvider
                v-if="accApplyPgmMngDetail.memberEmailYn"
                vid="01_member_006_email"
                :name="$t('content.accelerating.academy.MemberInfo.txt18')"
                :rules="{ required: true, email: true }"
                v-slot="{ failed }"
                slim
              >
                <BaseInputElement
                  ref="01_member_006_email"
                  v-model="accMemberInfo[0].email"
                  width="half"
                  id="email"
                  :placeholder="$t('content.accelerating.academy.MemberInfo.txt18')"
                  required
                  :invalid="failed"
                />
              </ValidationProvider>

              <!-- 추가항목(신규)
            : MBTI(입력필드)
            : 보유기술 스택(solidity, go, java, react, rust, chaincode 복수선택/체크박스)
            > (문구추가) 개발자만 입력
            : 프로그래밍 활용 능력(1~5/라디오 버튼)
              > (문구추가) 개발자만 입력 및 보통 1점 ~ 탁월함 5점
            : 백신 접종 여부(예/아니오 중 택일 /라디오 버튼)
              > (문구추가) 미 접종자의 경우 선정 시 참가 전 신속항원 결과서 제출 필수 -->

                <!-- MBTI -->
                <ValidationProvider
                  v-if="accApplyPgmMngDetail.mbtiYn"
                  vid="01_member_007_mbti"
                  :name="$t('content.accelerating.academy.MemberInfo.txt27')"
                  :rules="{ required: true }"
                  v-slot="{ failed }"
                  slim
                >
                  <BaseInputElement
                    ref="01_member_007_mbti"
                    v-model="accMemberInfo[0].mbti"
                    width="half"
                    id="mbti"
                    :placeholder="$t('content.accelerating.academy.MemberInfo.txt27')"
                    required
                    maxlength="4"
                    :invalid="failed"
                  />
                </ValidationProvider>
                <div
                  v-if="accApplyPgmMngDetail.mbtiYn && accApplyPgmMngDetail.accName.indexOf('해커톤') !== -1"
                  class="input-container">
                </div>
                <!-- 직책 -->
                <ValidationProvider
                  vid="01_member_003_position"
                  v-if="accApplyPgmMngDetail.positionYn && accApplyPgmMngDetail.accName.indexOf('해커톤') !== -1"
                  :name="$t('content.accelerating.academy.MemberInfo.txt05')"
                  :rules="{ required: true }"
                  slim
                >
                  <div class="input-container input-md multiple">
                    <BaseLabelElement
                      type="inline"
                      :label="$t('content.accelerating.academy.MemberInfo.txt05')"
                      required
                    />
                    <div>
                      <!-- 개발자, 기획자, 디자이너 -->
                      <BaseRadioButton
                        ref="01_member_003_position"
                        name="position"
                        v-model="accMemberInfo[0].position"
                        :value="$t('content.accelerating.academy.MemberInfo.txt24')"
                        required
                      >{{$t('content.accelerating.academy.MemberInfo.txt24')}}</BaseRadioButton>
                      <BaseRadioButton
                        name="position"
                        v-model="accMemberInfo[0].position"
                        :value="$t('content.accelerating.academy.MemberInfo.txt25')"
                        required
                      >{{$t('content.accelerating.academy.MemberInfo.txt25')}}</BaseRadioButton>
                      <BaseRadioButton
                        name="position"
                        v-model="accMemberInfo[0].position"
                        :value="$t('content.accelerating.academy.MemberInfo.txt26')"
                        required
                      >{{$t('content.accelerating.academy.MemberInfo.txt26')}}</BaseRadioButton>
                    </div>
                  </div>
                </ValidationProvider>
                <!-- 백신 접종 여부(예/아니오 중 택일 /라디오 버튼) -->
                <ValidationProvider
                  v-if="accApplyPgmMngDetail.vaccineYn"
                  vid="01_member_010_vaccine"
                  :name="$t('content.accelerating.academy.MemberInfo.txt30')"
                  :rules="{ required: true }"
                  slim
                >
                  <div class="input-container input-md multiple">
                    <BaseLabelElement
                      :label="$t('content.accelerating.academy.MemberInfo.txt30')"
                      required
                    />
                    <div>
                      <BaseRadioButton
                        :name="'01_member_010_vaccine'"
                        v-model="accMemberInfo[0].vaccine"
                        value="1"
                        required
                      >{{$t('content.accelerating.academy.CompanyInfo.txt56')}}</BaseRadioButton>
                      <BaseRadioButton
                        :name="'01_member_010_vaccine'"
                        v-model="accMemberInfo[0].vaccine"
                        value="0"
                        required
                      >{{$t('content.accelerating.academy.CompanyInfo.txt57')}}</BaseRadioButton>
                    </div>
                    <div class="input-info-desc">미 접종자의 경우 선정 시 참가 전 신속항원 결과서 제출 필수</div>
                  </div>
                </ValidationProvider>
                <!--
                  보유기술 스택
                  solidity, go, java, react, rust, chaincode
                -->
                <ValidationProvider
                  vid="01_member_008_skill"
                  v-if="accApplyPgmMngDetail.skillYn &&
                   (accMemberInfo[0].position === $t('content.accelerating.academy.MemberInfo.txt24'))"
                  :name="$t('content.accelerating.academy.MemberInfo.txt28')"
                  :rules="{ required: true }"
                  slim
                >
                  <div class="input-container input-md multiple">
                    <BaseLabelElement
                      :label="$t('content.accelerating.academy.MemberInfo.txt28')"
                      required
                    />
                    <div>
                      <BaseCheckbox
                        ref="01_member_008_skill"
                        v-model="accMemberInfo[0].skills"
                        value="solidity"
                      >solidity</BaseCheckbox>
                      <BaseCheckbox
                        ref="01_member_008_skill"
                        v-model="accMemberInfo[0].skills"
                        value="go"
                      >go</BaseCheckbox>
                      <BaseCheckbox
                        ref="01_member_008_skill"
                        v-model="accMemberInfo[0].skills"
                        value="java"
                      >java</BaseCheckbox>
                      <BaseCheckbox
                        ref="01_member_008_skill"
                        v-model="accMemberInfo[0].skills"
                        value="react"
                      >react</BaseCheckbox>
                      <BaseCheckbox
                        ref="01_member_008_skill"
                        v-model="accMemberInfo[0].skills"
                        value="rust"
                      >rust</BaseCheckbox>
                      <BaseCheckbox
                        ref="01_member_008_skill"
                        v-model="accMemberInfo[0].skills"
                        value="chaincode"
                      >chaincode</BaseCheckbox>
                    </div>
                    <div class="input-info-desc">개발자만 입력</div>
                  </div>
                </ValidationProvider>

                <!-- 프로그래밍 활용 능력 -->
                <ValidationProvider
                  vid="01_member_009_programing"
                  v-if="accApplyPgmMngDetail.programingLevelYn &&
                   (accMemberInfo[0].position === $t('content.accelerating.academy.MemberInfo.txt24'))"
                  :name="$t('content.accelerating.academy.MemberInfo.txt29')"
                  :rules="{ required: true }"
                  slim
                >
                  <div class="input-container input-md multiple">
                    <BaseLabelElement
                      :label="$t('content.accelerating.academy.MemberInfo.txt29')"
                      required
                    />
                    <div>
                      <BaseRadioButton
                        :name="'programing_level0'"
                        v-model="accMemberInfo[0].programing_level"
                        value="1"
                        required
                      >1</BaseRadioButton>
                      <BaseRadioButton
                        :name="'programing_level0'"
                        v-model="accMemberInfo[0].programing_level"
                        value="2"
                        required
                      >2</BaseRadioButton>
                      <BaseRadioButton
                        :name="'programing_level0'"
                        v-model="accMemberInfo[0].programing_level"
                        value="3"
                        required
                      >3</BaseRadioButton>
                      <BaseRadioButton
                        :name="'programing_level0'"
                        v-model="accMemberInfo[0].programing_level"
                        value="4"
                        required
                      >4</BaseRadioButton>
                      <BaseRadioButton
                        :name="'programing_level0'"
                        v-model="accMemberInfo[0].programing_level"
                        value="5"
                        required
                      >5</BaseRadioButton>
                    </div>
                    <div class="input-info-desc">개발자만 입력 및 보통 1점 ~ 탁월함 5점</div>
                  </div>
                </ValidationProvider>
              <span class="input-error-desc">{{ memberFormErrorMessage(0) }}</span>

              <!-- 멤버 - 추가 입력 정보 -->
              <ul
                v-if="accMemberInfo.length > 1"
                class="additional-info-wrap">
                <template
                  v-for="(additionalMemberInfo, index) in accMemberInfo">
                  <li
                    v-if="index > 0"
                    :key="index"
                    class="additional-info">

                    <!-- (AC114) Corporate ACC-Hyundai인 경우 : '멤버'대신에 '담당자'로 표시 -->
                    <template v-if="accApplyPgmMngDetail.accKind === 'AC114'">
                      <BaseLabelElement
                        :id="'name' + index"
                        :label="$t('commonCode.PL1004')"
                        class="input-title"
                      />
                    </template>
                    <!-- 그 외의 경우 : '멤버'로 표시 -->
                    <template v-else>
                      <BaseLabelElement
                        :id="'name' + index"
                        :label="$t('content.accelerating.academy.MemberInfo.txt01')"
                        class="input-title"
                      />
                    </template>

                    <!-- 이름 - 추가 입력 정보 -->
                    <ValidationProvider
                      v-if="accApplyPgmMngDetail.nameYn"
                      :vid="'01_member_' + index + '01_name'"
                      :name="$t('content.accelerating.academy.MemberInfo.txt02')"
                      :rules="{ required: true }"
                      v-slot="{ failed }"
                      slim
                    >
                      <BaseInputElement
                        :ref="'01_member_' + index + '01_name'"
                        v-model="additionalMemberInfo.name"
                        width="half"
                        :id="'name' + index"
                        :placeholder="$t('content.accelerating.academy.MemberInfo.txt02')"
                        required
                        :invalid="failed"
                      />
                    </ValidationProvider>

                    <!-- 성별 추가 입력 -->
                    <div
                      v-if="accApplyPgmMngDetail.member_gender && accApplyPgmMngDetail.accName.indexOf('해커톤') !== -1"
                      class="input-container input-md">
                      <ValidationProvider
                        :vid="'01_member_' + index + '02_gender'"
                        :name="$t('content.accelerating.academy.MemberInfo.txt23')"
                        :rules="{ required: true }"
                        slim
                      >
                        <!-- 남 -->
                        <BaseRadioButton
                          :ref="'01_member_' + index + '02_gender'"
                          :name="'gender' + index"
                          v-model="additionalMemberInfo.member_gender"
                          value="0"
                          required
                        >{{$t('content.accelerating.academy.MemberInfo.txt21')}}</BaseRadioButton>
                        <!-- 여 -->
                        <BaseRadioButton
                          :name="'gender' + index"
                          v-model="additionalMemberInfo.member_gender"
                          value="1"
                          required
                        >{{$t('content.accelerating.academy.MemberInfo.txt22')}}</BaseRadioButton>
                      </ValidationProvider>
                    </div>
                    <!-- 직책 - 추가 입력 정보 -->
                    <ValidationProvider
                      v-if="accApplyPgmMngDetail.positionYn && !(accApplyPgmMngDetail.accName.indexOf('해커톤') !== -1)"
                      :vid="'01_member_' + index + '03_position'"
                      :name="$t('content.accelerating.academy.MemberInfo.txt05')"
                      :rules="{ required: true }"
                      v-slot="{ failed }"
                      slim
                    >
                      <BaseInputElement
                        :ref="'01_member_' + index + '03_position'"
                        v-model="additionalMemberInfo.position"
                        width="half"
                        :id="'position' + index"
                        :placeholder="$t('content.accelerating.academy.MemberInfo.txt05')"
                        required
                        :invalid="failed"
                      />
                    </ValidationProvider>

                    <!-- 생년월일 - 추가 입력 정보 -->
                    <ValidationProvider
                      v-if="accApplyPgmMngDetail.careerYn"
                      :vid="'01_member_' + index + '04_career'"
                      :name="$t('content.accelerating.academy.MemberInfo.txt06')"
                      :rules="{ required: true }"
                      v-slot="{ failed }"
                      slim
                    >
                      <BaseInputElement
                        ref="'01_member_' + index + '04_career'"
                        v-model="additionalMemberInfo.career"
                        type="date"
                        width="half"
                        :id="'career' + index"
                        placeholder="생년월일"
                        required
                        :invalid="failed"
                        maxlength="8"
                      />
                    </ValidationProvider>

                    <!-- 소속 - 추가 입력 정보 -->
                    <ValidationProvider
                      v-if="accApplyPgmMngDetail.workerKindYn"
                      :vid="'01_member_' + index + '02_worker_kind'"
                      :name="$t('content.accelerating.academy.MemberInfo.txt19')"
                      :rules="{ required: true }"
                      v-slot="{ failed }"
                      slim
                    >
                      <BaseInputElement
                        :name="'worker_kind' + index"
                        v-model="additionalMemberInfo.department"
                        width="half"
                        :placeholder="$t('content.accelerating.academy.MemberInfo.txt19')"
                        required
                        :invalid="failed"
                      />
                    </ValidationProvider>

                    <!-- 휴대폰번호 - 추가 입력 정보 -->
                    <ValidationProvider
                      v-if="accApplyPgmMngDetail.memberCellphoneYn"
                      :vid="'01_member_' + index + '05_cellphone'"
                      :name="$t('content.accelerating.academy.MemberInfo.txt17')"
                      :rules="{ required: true, phone: true }"
                      v-slot="{ failed }"
                      slim
                    >
                      <BaseInputElement
                        :ref="'01_member_' + index + '05_cellphone'"
                        type="tel"
                        v-model="additionalMemberInfo.cellphone"
                        width="half"
                        :id="'cellphone' + index"
                        :placeholder="$t('content.accelerating.academy.MemberInfo.txt20')"
                        required
                        :invalid="failed"
                        maxlength="13"
                      />
                    </ValidationProvider>

                    <!-- 이메일 - 추가 입력 정보 -->
                    <ValidationProvider
                      v-if="accApplyPgmMngDetail.memberEmailYn"
                      :vid="'01_member_' + index + '06_email'"
                      :name="$t('content.accelerating.academy.MemberInfo.txt18')"
                      :rules="{ required: true, email: true }"
                      v-slot="{ failed }"
                      slim
                    >
                      <BaseInputElement
                        :ref="'01_member_' + index + '06_email'"
                        v-model="additionalMemberInfo.email"
                        width="half"
                        :id="'email' + index"
                        :placeholder="$t('content.accelerating.academy.MemberInfo.txt18')"
                        required
                        :invalid="failed"
                      />
                    </ValidationProvider>

                    <!-- MBTI - 추가 입력 정보 -->
                    <ValidationProvider
                      v-if="accApplyPgmMngDetail.mbtiYn"
                      :vid="'01_member_' + index + '07_mbti'"
                      :name="$t('content.accelerating.academy.MemberInfo.txt27')"
                      :rules="{ required: true }"
                      v-slot="{ failed }"
                      slim
                    >
                      <BaseInputElement
                        ref="01_member_007_mbti"
                        v-model="additionalMemberInfo.mbti"
                        width="half"
                        id="mbti"
                        :placeholder="$t('content.accelerating.academy.MemberInfo.txt27')"
                        required
                        maxlength="4"
                        :invalid="failed"
                      />
                    </ValidationProvider>
                    <div
                      v-if="accApplyPgmMngDetail.mbtiYn && accApplyPgmMngDetail.accName.indexOf('해커톤') !== -1"
                      class="input-container">
                    </div>

                    <!-- 직책 -->
                    <ValidationProvider
                      :vid="'01_member_' + index + '03_position'"
                      v-if="accApplyPgmMngDetail.positionYn && accApplyPgmMngDetail.accName.indexOf('해커톤') !== -1"
                      :name="$t('content.accelerating.academy.MemberInfo.txt05')"
                      :rules="{ required: true }"
                      slim
                    >
                      <div class="input-container input-md multiple">
                        <BaseLabelElement
                          type="inline"
                          :label="$t('content.accelerating.academy.MemberInfo.txt05')"
                          required
                        />
                        <div>
                          <!-- 개발자, 기획자, 디자이너 -->
                          <BaseRadioButton
                            :name="'01_member_' + index + '03_position'"
                            v-model="additionalMemberInfo.position"
                            :value="$t('content.accelerating.academy.MemberInfo.txt24')"
                            required
                          >{{$t('content.accelerating.academy.MemberInfo.txt24')}}</BaseRadioButton>
                          <BaseRadioButton
                            :name="'01_member_' + index + '03_position'"
                            v-model="additionalMemberInfo.position"
                            :value="$t('content.accelerating.academy.MemberInfo.txt25')"
                            required
                          >{{$t('content.accelerating.academy.MemberInfo.txt25')}}</BaseRadioButton>
                          <BaseRadioButton
                            :name="'01_member_' + index + '03_position'"
                            v-model="additionalMemberInfo.position"
                            :value="$t('content.accelerating.academy.MemberInfo.txt26')"
                            required
                          >{{$t('content.accelerating.academy.MemberInfo.txt26')}}</BaseRadioButton>
                        </div>
                      </div>
                    </ValidationProvider>
                    <!-- 백신 접종 여부(예/아니오 중 택일 /라디오 버튼) -->
                    <ValidationProvider
                      v-if="accApplyPgmMngDetail.vaccineYn"
                      :vid="'01_member_' + index + '10_vaccine'"
                      :name="$t('content.accelerating.academy.MemberInfo.txt30')"
                      :rules="{ required: true }"
                      slim
                    >
                      <div class="input-container input-md multiple">
                        <BaseLabelElement
                          :label="$t('content.accelerating.academy.MemberInfo.txt30')"
                          required
                        />
                        <div>
                          <BaseRadioButton
                            :name="'01_member_' + index + '10_vaccine'"
                            v-model="additionalMemberInfo.vaccine"
                            value="1"
                            required
                          >{{$t('content.accelerating.academy.CompanyInfo.txt56')}}</BaseRadioButton>
                          <BaseRadioButton
                            :name="'01_member_' + index + '10_vaccine'"
                            v-model="additionalMemberInfo.vaccine"
                            value="0"
                            required
                          >{{$t('content.accelerating.academy.CompanyInfo.txt57')}}</BaseRadioButton>
                        </div>
                        <div class="input-info-desc">미 접종자의 경우 선정 시 참가 전 신속항원 결과서 제출 필수</div>
                      </div>
                    </ValidationProvider>
                    <!--
                      보유기술 스택
                      solidity, go, java, react, rust, chaincode
                    -->
                    <ValidationProvider
                      :vid="'01_member_' + index + '08_skill'"
                      v-if="accApplyPgmMngDetail.skillYn &&
                      (additionalMemberInfo.position === $t('content.accelerating.academy.MemberInfo.txt24'))"
                      :name="$t('content.accelerating.academy.MemberInfo.txt28')"
                      :rules="{ required: true }"
                      slim
                    >
                      <div class="input-container input-md multiple">
                        <BaseLabelElement
                          :label="$t('content.accelerating.academy.MemberInfo.txt28')"
                          required
                        />
                        <div>
                          <BaseCheckbox
                            :ref="'01_member_' + index + '08_skill'"
                            v-model="additionalMemberInfo.skills"
                            value="solidity"
                          >solidity</BaseCheckbox>
                          <BaseCheckbox
                            :ref="'01_member_' + index + '08_skill'"
                            v-model="additionalMemberInfo.skills"
                            value="go"
                          >go</BaseCheckbox>
                          <BaseCheckbox
                            :ref="'01_member_' + index + '08_skill'"
                            v-model="additionalMemberInfo.skills"
                            value="java"
                          >java</BaseCheckbox>
                          <BaseCheckbox
                            :ref="'01_member_' + index + '08_skill'"
                            v-model="additionalMemberInfo.skills"
                            value="react"
                          >react</BaseCheckbox>
                          <BaseCheckbox
                            :ref="'01_member_' + index + '08_skill'"
                            v-model="additionalMemberInfo.skills"
                            value="rust"
                          >rust</BaseCheckbox>
                          <BaseCheckbox
                            :ref="'01_member_' + index + '08_skill'"
                            v-model="additionalMemberInfo.skills"
                            value="chaincode"
                          >chaincode</BaseCheckbox>
                        </div>
                        <div class="input-info-desc">개발자만 입력</div>
                      </div>
                    </ValidationProvider>

                    <!-- 프로그래밍 활용 능력 -->
                    <ValidationProvider
                      :vid="'01_member_' + index + '09_programing'"
                      v-if="accApplyPgmMngDetail.programingLevelYn &&
                      (additionalMemberInfo.position === $t('content.accelerating.academy.MemberInfo.txt24'))"
                      :name="$t('content.accelerating.academy.MemberInfo.txt29')"
                      :rules="{ required: true }"
                      slim
                    >
                      <div class="input-container input-md multiple">
                        <BaseLabelElement
                          :label="$t('content.accelerating.academy.MemberInfo.txt29')"
                          required
                        />
                        <div>
                          <BaseRadioButton
                            :name="'01_member_' + index + '09_programing'"
                            v-model="additionalMemberInfo.programing_level"
                            value="1"
                            required
                          >1</BaseRadioButton>
                          <BaseRadioButton
                            :name="'01_member_' + index + '09_programing'"
                            v-model="additionalMemberInfo.programing_level"
                            value="2"
                            required
                          >2</BaseRadioButton>
                          <BaseRadioButton
                            :name="'01_member_' + index + '09_programing'"
                            v-model="additionalMemberInfo.programing_level"
                            value="3"
                            required
                          >3</BaseRadioButton>
                          <BaseRadioButton
                            :name="'01_member_' + index + '09_programing'"
                            v-model="additionalMemberInfo.programing_level"
                            value="4"
                            required
                          >4</BaseRadioButton>
                          <BaseRadioButton
                            :name="'01_member_' + index + '09_programing'"
                            v-model="additionalMemberInfo.programing_level"
                            value="5"
                            required
                          >5</BaseRadioButton>
                        </div>
                        <div class="input-info-desc">개발자만 입력 및 보통 1점 ~ 탁월함 5점</div>
                      </div>
                    </ValidationProvider>

                    <span class="input-error-desc">{{ memberFormErrorMessage(index) }}</span>

                    <!-- 삭제 버튼 -->
                    <BaseButton
                      type="line"
                      size="small"
                      @click="deleteMemberInfo(index)"
                    >{{$t('content.common.button.btn07')}}</BaseButton>
                  </li>
                </template>
              </ul>

              <div
                class="btn-wrap">
                <!-- 추가 버튼 -->
                <BaseButton
                  type="line"
                  size="medium"
                  @click="addMemberInfo"
                >{{$t('content.common.button.btn18')}}</BaseButton>
              </div>
              <!-- // 멤버 - 추가 입력 정보 -->
            </li>
            <!-- // 멤버 -->

            <!-- 창업 프로그램 참여경험 -->
            <li
              v-if="accApplyPgmMngDetail.accStartPgJoinExpYn"
              class="form-row">
              <BaseLabelElement
                :label="$t('content.accelerating.academy.MemberInfo.txt07')"
              />
              <!-- 시작일 -->
              <TypeableDatepicker
                v-model="accStartPgJoinExp[0].join_start_date"
                width="half"
                id="join_start_date"
                :disabledFrom="accStartPgJoinExp[0].join_end_date"
                :placeholder="$t('content.accelerating.academy.MemberInfo.txt08') + ' (YYYY-MM-DD)'"
                initialView="year"
              />
              <!-- 종료일 -->
              <TypeableDatepicker
                v-model="accStartPgJoinExp[0].join_end_date"
                width="half"
                id="join_end_date"
                :disabledTo="accStartPgJoinExp[0].join_start_date"
                :placeholder="$t('content.accelerating.academy.MemberInfo.txt09') + ' (YYYY-MM-DD)'"
                initialView="year"
              />
              <!-- 프로그램 -->
              <BaseInputElement
                v-model="accStartPgJoinExp[0].start_pg_name"
                id="start_pg_name"
                :placeholder="$t('content.accelerating.academy.MemberInfo.txt10')"
              />
              <!-- 수상 -->
              <div class="flex-container">
                <BaseCheckbox
                  id="aword_yn"
                  v-model="accStartPgJoinExp[0].aword_yn"
                  trueValue="1"
                  falseValue="0"
                >{{$t('content.accelerating.academy.MemberInfo.txt11')}}</BaseCheckbox>
                <!-- 수여 혜택 -->
                <BaseInputElement
                  v-model="accStartPgJoinExp[0].benefit"
                  id="benefit"
                  :placeholder="$t('content.accelerating.academy.MemberInfo.txt12')"
                  :disabled="accStartPgJoinExp[0].aword_yn === '0'"
                />
              </div>

              <!-- 최근 참여경험부터 입력해 주세요. -->
              <span class="input-desc">{{$t('content.accelerating.academy.MemberInfo.txt13')}}</span>

              <!-- 창업 프로그램 참여경험 - 추가 입력 정보 -->
              <ul
                v-if="accStartPgJoinExp.length > 1"
                class="additional-info-wrap">
                <template
                  v-for="(additionalStartPgJoinExp, index) in accStartPgJoinExp"
                >
                  <li
                    v-if="index > 0"
                    :key="index"
                    class="additional-info">
                    <BaseLabelElement
                      :id="'name' + index"
                      :label="$t('content.accelerating.academy.MemberInfo.txt07')"
                      class="input-title"
                    />
                    <!-- 시작일 - 추가 입력 정보 -->
                    <TypeableDatepicker
                      v-model="additionalStartPgJoinExp.join_start_date"
                      width="half"
                      :id="'join_start_date' + index"
                      :disabledFrom="additionalStartPgJoinExp.join_end_date"
                      :placeholder="$t('content.accelerating.academy.MemberInfo.txt08') + ' (YYYY-MM-DD)'"
                      initialView="year"
                    />
                    <!-- 종료일 - 추가 입력 정보 -->
                    <TypeableDatepicker
                      v-model="additionalStartPgJoinExp.join_end_date"
                      width="half"
                      :id="'join_end_date' + index"
                      :disabledTo="additionalStartPgJoinExp.join_start_date"
                      :placeholder="$t('content.accelerating.academy.MemberInfo.txt09') + ' (YYYY-MM-DD)'"
                      initialView="year"
                    />
                    <!-- 프로그램 - 추가 입력 정보 -->
                    <BaseInputElement
                      v-model="additionalStartPgJoinExp.start_pg_name"
                      :id="'start_pg_name' + index"
                      :placeholder="$t('content.accelerating.academy.MemberInfo.txt10')"
                    />
                    <!-- 수상 - 추가 입력 정보 -->
                    <div class="flex-container">
                      <BaseCheckbox
                        :id="'aword_yn' + index"
                        v-model="additionalStartPgJoinExp.aword_yn"
                        trueValue="1"
                        falseValue="0"
                      >{{$t('content.accelerating.academy.MemberInfo.txt11')}}</BaseCheckbox>
                      <!-- 수여 혜택 - 추가 입력 정보 -->
                      <BaseInputElement
                        v-model="additionalStartPgJoinExp.benefit"
                        :id="'benefit' + index"
                        :placeholder="$t('content.accelerating.academy.MemberInfo.txt12')"
                        :disabled="additionalStartPgJoinExp.aword_yn === '0'"
                      />
                    </div>

                    <!-- 삭제 버튼 -->
                    <BaseButton
                      type="line"
                      size="small"
                      @click="deleteStartPgJoinExp(index)"
                    >{{$t('content.common.button.btn07')}}</BaseButton>
                  </li>
                </template>
              </ul>

              <div
                v-if="accStartPgJoinExp.length < LIMIT_START_PG_JOIN_EXP"
                class="btn-wrap">
                <!-- 추가 버튼 -->
                <BaseButton
                  type="line"
                  size="medium"
                  @click="addStartPgJoinExp"
                >{{$t('content.common.button.btn18')}}</BaseButton>
              </div>
              <!-- // 창업 프로그램 참여경험 - 추가 입력 정보 -->
            </li>
            <!-- // 창업 프로그램 참여경험 -->

            <!-- 멘토 -->
            <li
              v-if="accApplyPgmMngDetail.accMentorInfoYn"
              class="form-row">
              <BaseLabelElement
                :label="$t('content.accelerating.academy.MemberInfo.txt14')"
              />
              <!-- 멘토 이름 -->
              <BaseInputElement
                v-model="accMentorInfo[0].mento_name"
                width="half"
                id="mento_name"
                :placeholder="$t('content.accelerating.academy.MemberInfo.txt15')"
              />
              <!-- 멘토 주요 이력 -->
              <BaseInputElement
                v-model="accMentorInfo[0].mento_career"
                width="half"
                id="mento_career"
                :placeholder="$t('content.accelerating.academy.MemberInfo.txt16')"
              />

              <!-- 멘토 - 추가 입력 정보 -->
              <ul
                v-if="accMentorInfo.length > 1"
                class="additional-info-wrap">
                <template
                  v-for="(additionalMentorInfo, index) in accMentorInfo"
                >
                  <li
                    v-if="index > 0"
                    :key="index"
                    class="additional-info">
                    <BaseLabelElement
                      :label="$t('content.accelerating.academy.MemberInfo.txt14')"
                      class="input-title"
                    />
                    <!-- 멘토 이름 - 추가 입력 정보 -->
                    <BaseInputElement
                      v-model="additionalMentorInfo.mento_name"
                      width="half"
                      :id="'mento_name' + index"
                      :placeholder="$t('content.accelerating.academy.MemberInfo.txt15')"
                    />
                    <!-- 멘토 주요 이력 - 추가 입력 정보 -->
                    <BaseInputElement
                      v-model="additionalMentorInfo.mento_career"
                      width="half"
                      :id="'mento_career' + index"
                      :placeholder="$t('content.accelerating.academy.MemberInfo.txt16')"
                    />

                    <!-- 삭제 버튼 -->
                    <BaseButton
                      type="line"
                      size="small"
                      @click="deleteMentorInfo(index)"
                    >{{$t('content.common.button.btn07')}}</BaseButton>
                  </li>
                </template>
              </ul>

              <div
                v-if="accMentorInfo.length < LIMIT_MENTO_INFO"
                class="btn-wrap">
                <!-- 추가 버튼 -->
                <BaseButton
                  type="line"
                  size="medium"
                  @click="addMentorInfo"
                >{{$t('content.common.button.btn18')}}</BaseButton>
              </div>
              <!-- // 멘토 - 추가 입력 정보 -->
            </li>
            <!-- // 멘토 -->
          </ul>
        </fieldset>

        <!-- 버튼 -->
        <div class="btn-wrap type2">
          <BackButton
            @click="$emit('back')"
          />
          <BaseButton
            type="line"
            @click="$emit('save')"
          >{{$t('content.common.button.btn01')}}</BaseButton>
          <BaseButton
            type="line"
            @click="doPrev"
          >{{$t('content.common.button.btn03')}}</BaseButton>
          <BaseButton
            @click="doNext"
          >{{$t('content.common.button.btn02')}}</BaseButton>
        </div>
        <!-- // 버튼 -->
      </form>
      <!-- // 멤버정보 입력 폼 -->
    </ValidationObserver>
  </div>
</template>

<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import { mixin as mixinHelperUtils } from '@/common/helperUtils';
import BaseLabelElement from '@/components/base/BaseLabelElement.vue';
import BaseInputElement from '@/components/base/BaseInputElement.vue';
import BaseRadioButton from '@/components/base/BaseRadioButton.vue';
import BaseCheckbox from '@/components/base/BaseCheckbox.vue';
import BaseButton from '@/components/base/BaseButton.vue';
import BackButton from '@/components/base/BackButton.vue';
import TypeableDatepicker from '@/components/base/TypeableDatepicker.vue';
import AccMemberInfoDto from '../dto/apply/AccMemberInfoDto';
import AccStartPgJoinExpDto from '../dto/apply/AccStartPgJoinExpDto';
import AccMentorInfoDto from '../dto/apply/AccMentorInfoDto';

/**
 * Programs > 공모지원 & 공모지원내역 > 공모 지원 (탭) - 2단계 : 멤버정보
 */
export default {
  name: 'ProgramsApplyStep2',
  mixins: [mixinHelperUtils],
  components: {
    ValidationObserver, // https://logaretm.github.io/vee-validate/api/validation-observer.html
    ValidationProvider, // https://logaretm.github.io/vee-validate/api/validation-provider.html
    BaseLabelElement,
    BaseInputElement,
    BaseRadioButton,
    BaseCheckbox,
    BaseButton,
    BackButton,
    TypeableDatepicker,
  },

  props: {
    /**
     * Acc지원프로그램 관리 상세
     * @type {import('../dto/AccApplyPgmMngDetailDto').default}
     */
    accApplyPgmMngDetail: {
      type: Object,
      required: true,
    },
    /**
     * Acc지원 정보 - 멤버
     * @type {AccMemberInfoDto[]}
     * @see AccApplyDto
     */
    accMemberInfo: {
      type: Array,
      required: true,
    },
    /**
     * Acc지원 정보 - 창업 프로그램 참여경험
     * @type {AccStartPgJoinExpDto[]}
     * @see AccApplyDto
     */
    accStartPgJoinExp: {
      type: Array,
      required: true,
    },
    /**
     * Acc지원 정보 - 멘토
     * @type {AccMentorInfoDto[]}
     * @see AccApplyDto
     */
    accMentorInfo: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      /**
       * 창업 프로그램 참여경험 입력 개수 제한
       * @type {number}
       */
      LIMIT_START_PG_JOIN_EXP: 3,
      /**
       * 멘토 입력 개수 제한
       * @type {number}
       */
      LIMIT_MENTO_INFO: 3,
    };
  },

  /**
   * beforeRouteEnter
   * @see {@link https://router.vuejs.org/guide/advanced/navigation-guards.html#in-component-guards In-Component Guards}
   */
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (vm.accApplyPgmMngDetail.isEmptyStep2()) {
        // 입력할 것이 없으면 스텝 건너뛰기
        if (from && from.name === 'ProgramsApplyStep3') {
          vm.doPrev();
        } else {
          vm.doNext();
        }
      }
    });
  },

  methods: {
    /**
     * 멤버 - 유효성 검사 에러 메시지
     * @param {number} index
     */
    memberFormErrorMessage(index) {
      if (this.$refs.form) {
        /**
         * 멤버 - vid, ref 부여
         * 01_member_001_name, 01_member_002_worker_kind, 01_member_003_position, ...
         * 01_member_101_name, 01_member_102_worker_kind, 01_member_103_position, ...
         * 01_member_201_name, 01_member_202_worker_kind, 01_member_203_position, ...
         */
        const firstErrorVid = Object.keys(this.$refs.form.errors)
          .find((key) => key.startsWith(`01_member_${index}`) && this.$refs.form.errors[key].length > 0);
        if (firstErrorVid) {
          return this.$refs.form.errors[firstErrorVid].join(', ');
        }
      }
      return '';
    },

    doNext() {
      this.validateForm(this.$refs.form) // mixinHelperUtils
        .then(() => {
          this.$router.push({
            name: 'ProgramsApplyStep3',
          });
        })
        .catch(() => {
          // https://github.com/logaretm/vee-validate/issues/2781 이슈로
          // memberFormErrorMessage 표시를 위해서 강제 업데이트
          // https://vuejs.org/v2/guide/components-edge-cases.html#Forcing-an-Update
          this.$forceUpdate();
        });
    },
    doPrev() {
      this.$router.push({
        name: 'ProgramsApplyStep1',
      });
    },

    /**
     * 멤버 - 추가 입력 정보 추가
     */
    addMemberInfo() {
      if (this.accMemberInfo.length === 4) {
        this.showAlert('최대 4인까지만 입력할 수 있습니다.');
      } else {
        this.accMemberInfo.push(new AccMemberInfoDto({}));
      }
    },
    /**
     * 멤버 - 추가 입력 정보 삭제
     */
    deleteMemberInfo(inx) {
      this.accMemberInfo.splice(inx, 1);
    },

    /**
     * 창업 프로그램 참여경험 - 추가 입력 정보 추가
     */
    addStartPgJoinExp() {
      if (this.accStartPgJoinExp.length < this.LIMIT_START_PG_JOIN_EXP) {
        this.accStartPgJoinExp.push(new AccStartPgJoinExpDto({}));
      }
    },
    /**
     * 창업 프로그램 참여경험 - 추가 입력 정보 삭제
     */
    deleteStartPgJoinExp(index) {
      if (index < this.LIMIT_START_PG_JOIN_EXP) {
        this.accStartPgJoinExp.splice(index, 1);
      }
    },

    /**
     * 멘토 - 추가 입력 정보 추가
     */
    addMentorInfo() {
      if (this.accMentorInfo.length < this.LIMIT_MENTO_INFO) {
        this.accMentorInfo.push(new AccMentorInfoDto({}));
      }
    },
    /**
     * 멘토 - 추가 입력 정보 삭제
     */
    deleteMentorInfo(index) {
      if (index < this.LIMIT_MENTO_INFO) {
        this.accMentorInfo.splice(index, 1);
      }
    },
  },
};
</script>
<style scoped>
  .input-container.input-md.multiple {
    height: 81px;
    padding: 5px 0;
    box-sizing: content-box;
    vertical-align: middle;
  }
  .input-container.input-md .checkbox-container {
    display: inline-block;
    margin: 6px 11px 6px 0;
  }
  .additional-info .input-container.input-md .checkbox-container {
    display: inline-block;
    margin-right: 6px;
  }
  .input-container.multiple .radio-container {
    margin-right: 56px;
  }
  .additional-info .input-container.multiple .radio-container {
    margin-right: 50px;
  }
  .input-container.input-md .inp-title.inline {
    margin-right: 10px;
    vertical-align: middle;
  }
  .radio-container {
    vertical-align: middle;
  }
  .input-info-desc {
    display: block;
    font-size: 13px;
    font-weight: 500;
    line-height: 1.38;
    color: #3190d6;
  }
  .checkbox-container {
    margin-top: 10px;
  }
</style>
